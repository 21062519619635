import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";
import PrivacyPolicyContent from "page_components/PrivacyPolicy";

const PrivacyPolicy = ({ location, data: { contact } }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Polityka prywatności",
        description: "",
      }}
      contact_data={contact?.acfContact}
    >
      <PageHeader label="Polityka prywatności" />
      <PrivacyPolicyContent />
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query {
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
