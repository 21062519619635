import "./styles.scss";
import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <section className="policy-section">
      <div className="container">
        <h2>
          Klauzula informacyjna o przetwarzaniu danych osobowych Informacje
          podstawowe dotyczące przetwarzania danych osobowych klientów
        </h2>
        <span>Administrator Danych</span>
        <p>
          HUBPOINT Spółka z ograniczoną odpowiedzialnością Spółka komandytowa z
          siedzibą w Krakowie przy ul. Zawiłej nr 57, 30-390 Kraków, wpisaną do
          rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd
          Rejonowy dla Krakowa-Śródmieścia w Krakowie Wydział XI Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS 0000716584, NIP
          6762544428, REGON 369365000
        </p>
        <span>Cele przetwarzania</span>
        <ul>
          <li>doprowadzenie do zawarcia umowy,</li>
          <li>wykonanie umowy</li>
          <li>
            wykonanie umowy zawartej z instytucją kredytową finansującą
            realizację inwestycji,
          </li>
          <li>dochodzenie i obrona przed roszczeniami,</li>
          <li>
            kontakt z klientem dla wykonania obowiązków informacyjnych
            wynikających z zawartych umów oraz obowiązujących przepisów,
          </li>
          <li>wykonanie obowiązków podatkowych i rachunkowych;</li>
        </ul>

        <span>Uzyskanie informacji o przetwarzaniu danych osobowych</span>
        <ul>
          <li>e-mail: biuro@hubpoint.pl</li>
          <li>telefon kontaktowy: 513 030 484</li>
        </ul>

        <span>Podstawy prawne przetwarzania</span>
        <ul>
          <li>zgoda,</li>
          <li>obowiązek prawny,</li>
          <li>umowa,</li>
          <li>uzasadniony interes Administratora Danych;</li>
        </ul>

        <span>Odbiorcy danych</span>
        <ul>
          <li>podmioty przetwarzające dane w imieniu Administratora Danych,</li>
          <li>podmioty świadczące usługi pocztowe i kurierskie,</li>
          <li>banki i inne instytucje kredytowe,</li>
          <li>
            podmioty wspierające Administratora Danych w prowadzonej
            działalności;
          </li>
        </ul>

        <span>Prawa związane z przetwarzaniem danych</span>
        <ul>
          <li>prawo dostępu do danych osobowych,</li>
          <li>
            prawo żądania sprostowania, udostępnienia, poprawienia, usunięcia
            danych osobowych,
          </li>
          <li>prawo żądania ograniczenia przetwarzania danych osobowych,</li>
          <li>prawo do przenoszenia danych osobowych,</li>
          <li>prawo do cofnięcia zgody,</li>
          <li>prawo wniesienia sprzeciwu.</li>
        </ul>
        <h3>Szczegółowe informacje dotyczące przetwarzania danych osobowych</h3>
        <p>
          Zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływ takich danych oraz uchylenia
          dyrektywy 95/46/WE (RODO), informujemy, że:
        </p>
        <span>I. Administrator Danych Osobowych</span>
        <p>
          Administratorem Pana/Pani danych osobowych jest Spółka pod firmą
          HUBPOINT Spółka z ograniczoną odpowiedzialnością Spółka komandytowa z
          siedzibą w Krakowie przy ul. Zawiłej nr 57, 30-390 Kraków, wpisaną do
          rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd
          Rejonowy dla Krakowa-Śródmieścia w Krakowie Wydział XI Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS 0000716584, NIP
          6762544428, REGON 369365000 (w treści także „Spółka”).
          <br />
          <br />
          Może Pan/Pani skontaktować się ze Spółką w następujący sposób:
        </p>
        <ul>
          <li>pisemnie na adres: ul. Zawiła 57c, 30-390 Kraków</li>
          <li>elektronicznie na adres: biuro@hubpoint.pl</li>
          <li>telefonicznie: 513 030 484</li>
        </ul>
        <span>
          II. Cele przetwarzania danych osobowych oraz podstawa prawna ich
          przetwarzania
        </span>
        <p>
          Spółka przetwarza Pana/Pani dane osobowe, ponieważ jest to niezbędne
          dla:
        </p>
        <ul>
          <li>
            zawarcia i wykonania z Panem/Panią umowy, w tym także doprowadzenia
            do zawarcia umowy (art. 6 ust. 1 lit. b RODO);
          </li>
          <li>
            kontaktowania się z Panią/Panem w celu informowania o wykonywaniu
            umowy oraz w wykonaniu obowiązków wynikających z przepisów prawa
            (art. 6 ust. 1 lit. b i c RODO);
          </li>
          <li>
            kontaktowania się z Panią/Panem w celu informowania o warunkach
            zawarcia umowy, kontaktowania się w odpowiedzi na wypełniony przez
            Panią / Pana formularz kontaktowy (art. 6 ust. 1 lit. a i b RODO);
          </li>
          <li>
            celów podatkowych i rachunkowych w związku z wykonaniem zawartej z
            Panem/Panią umowy, dla wypełnienia obowiązku prawnego ciążącego na
            Spółce (art. 6 ust. 1 lit. c RODO);
          </li>
          <li>
            archiwizowania dokumentacji związanej z wykonaniem zawartej z
            Panem/Panią umowy lub umów, do momentu przedawnienia roszczeń z nich
            wynikających, także dla zabezpieczenia informacji, na wypadek
            prawnej potrzeby wykazania faktów, co jest prawnie uzasadnionym
            interesem Spółki (art. 6 ust. 1 lit. f RODO);
          </li>
          <li>
            zawarcia i wykonania umowy zawartej z instytucją kredytową (w tym
            bankiem) finansującą realizację inwestycji, co jest prawnie
            uzasadnionym interesem Spółki (art. 6 ust. 1 lit. f RODO);
          </li>
          <li>
            ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami
            wynikającymi z zawartej przez Spółkę z Panem/Panią umowy, co jest
            prawnie uzasadnionym interesem Spółki (art. 6 ust. 1 lit. f RODO).
          </li>
        </ul>
        <p>Spółka przetwarza Pana/Pani dane osobowe na podstawie:</p>
        <ul>
          <li>zgody wyrażonej przez Pana/Panią,</li>
          <li>umowy zawartej przez Pana/Panią ze Spółką,</li>
          <li>obowiązujących przepisów prawa w szczególności:</li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 17 listopada 1964 r. – Kodeks cywilny,
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 29 września 1994 r. o rachunkowości,
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 06 lipca 1982 r. o księgach wieczystych i hipotece,
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 29 sierpnia 1997 r. – Ordynacja podatkowa,
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług;
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 26 lipca 1991 r. o podatku dochodowym od osób
            fizycznych;
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 26 lipca 1991 r. o podatku dochodowym od osób
            prawnych;
          </li>
          <li className="policy-section__no-list-style">
            ustawy z dnia 26 sierpnia 1997 r. – Prawo bankowe;
          </li>
          <li>
            prawnie uzasadnionego interesu Spółki, którym jest między innymi:
            wykonanie obowiązków podatkowych i rachunkowych; ustalenie,
            zabezpieczenie, dochodzenie roszczeń lub obrona przed roszczeniami;
          </li>
        </ul>
        <span>III. Odbiorcy danych osobowych</span>
        <ul>
          <li className="policy-section__no-list-style">
            1. podmiotom, przetwarzającym dane w imieniu Spółki (w tym
            Prokurentom Spółki, członkom jej organów zarządzających lub
            nadzorczych, pracownikom lub współpracownikom);
          </li>
          <li className="policy-section__no-list-style">
            2. podmiotom świadczącym usługi pocztowe lub kurierskie w celu
            dostarczenia Panu/Pani korespondencji;
          </li>
          <li className="policy-section__no-list-style">
            3. bankom lub innym instytucjom kredytowym finansującym realizację
            inwestycji
          </li>
          <li className="policy-section__no-list-style">
            4. podwykonawcom, czyli podmiotom, z których usług Spółka korzysta
            przy ich przetwarzaniu:
          </li>
          <li>firmie księgowej,</li>
          <li>kancelarii prawnej,</li>
          <li>kancelarii notarialnej,</li>
          <li>firmie informatycznej,</li>
          <li>doradcy inwestycyjnemu,</li>
          <li>instytucji kredytowej (bank),</li>
          <li>
            firmie pośredniczącej w obsłudze wykonania umowy kredytu zawartej w
            celu finansowania realizacji inwestycji,
          </li>
          <li>
            firmom uczestniczącym w procesie budowlanym z związku z realizacją
            inwestycji (np. generalnemu wykonawcy inwestycji lub jego
            podwykonawcy, serwisantom czy gwarantom prac lub materiałów i
            urządzeń użytych do realizacji inwestycji) w celu zagwarantowania
            należytego wykonania zawartej z Panem/Panią umowy, w tym także
            realizacji ewentualnych roszczeń z tytułu należytego wykonania
            umowy, jakości wykonanych prac oraz użytych materiałów
          </li>
          <li>komplementariuszowi Spółki</li>
          <li className="policy-section__no-list-style">
            Takie podmioty przetwarzają dane osobowe na podstawie umowy zawartej
            ze Spółką i tylko zgodnie z poleceniem Spółki. Takie podmioty mogą
            udostępniać dane osobowe dalszym podwykonawcom, wyłącznie na
            podstawie umowy ze Spółką, za wiedzą i informacją Spółki, i tylko
            zgodnie z poleceniem Spółki. Jednakże dalszymi podwykonawcami mogą
            być wyłącznie następujące podmioty, z których podwykonawcy Spółki
            korzystają przy przetwarzaniu danych osobowych: firma księgowa,
            kancelaria prawna, kancelaria notarialna, firma informatyczna,
            doradca inwestycyjny, instytucja kredytowa (bank), firma
            pośrednicząca w obsłudze wykonania umowy kredytu zawartej w celu
            finansowania realizacji inwestycji.
          </li>
        </ul>
        <span>IV. Okres przechowywania danych osobowych</span>
        <ul>
          <li className="policy-section__no-list-style">
            Zgodnie z art. 13 ust. 2 lit a) RODO, Pana/Pani dane osobowe
            przechowujemy:
          </li>
          <li>
            dane osobowe pozyskane w celu zawarcia umowy, która ostatecznie nie
            została zawarta, Spółka przechowuje przez okres negocjowania umowy
            oraz do końca roku kalendarzowego, następującego po roku
            kalendarzowym, w którym ostatni raz skontaktował/a się Pan/Pani ze
            Spółką;
          </li>
          <li>
            dane osobowe pozyskane w związku z zawarciem i wykonaniem umowy
            Spółka przechowuje przez czas obowiązywania umowy oraz po jej
            zakończeniu maksymalnie do końca okresu:
          </li>
          <li className="policy-section__dash">
            przedawnienia potencjalnych roszczeń z umowy, oraz
          </li>
          <li className="policy-section__dash">
            przedawnienia wykonania obowiązków rachunkowych lub podatkowych,
            Dane są przechowywane przez wskazany maksymalny okres w celach:
          </li>
          <li className="policy-section__dash">
            dochodzenia roszczeń wynikających w związku z wykonaniem umowy,
          </li>
          <li className="policy-section__dash">
            wykonania obowiązków wynikających z przepisów prawa, w tym w
            szczególności rachunkowych i podatkowych.
          </li>
        </ul>
        <span>
          V. Prawa Pana/Pani związane z przetwarzaniem danych osobowych
        </span>
        <ul>
          <li className="policy-section__no-list-style">
            Panu/Pani przysługuje:
          </li>
          <li>
            prawo dostępu do swoich danych osobowych oraz uzyskania kopii danych
            osobowych przechowywanych przez Spółkę;
          </li>
          <li>
            prawo żądania sprostowania (poprawienia) Pana/Pani danych osobowych;
          </li>
          <li>
            prawo żądania usunięcia Pana/Pani danych osobowych, jeżeli uważa
            Pan/Pani, iż nie ma podstaw do tego, aby Spółka przetwarzała
            Pana/Pani dane osobowe;
          </li>
          <li>
            prawo żądania ograniczenia przetwarzania przez Spółkę Pana/Pani
            danych osobowych; może Pan/Pani zażądać, aby Spółka ograniczyła
            przetwarzanie Pana/Pani danych osobowych do ich przechowywania lub
            wykonywania uzgodnionych z Panem/Panią działań:
          </li>
          <li className="policy-section__no-list-style">
            gdy zdaniem Pana/Pani Spółka ma nieprawidłowe dane osobowe lub
            przetwarza je bezpodstawnie; lub
          </li>
          <li className="policy-section__no-list-style">
            jeżeli nie chce Pan/Pani, aby dane zostały usunięte, albowiem mogą
            one być potrzebne Panu/Pani do ustalenia, dochodzenia lub obrony
            przed roszczeniami; lub
          </li>
          <li className="policy-section__no-list-style">
            na czas wniesionego przez Pana/Panią sprzeciwu względem
            przetwarzania danych osobowych;
          </li>
          <li>
            prawo wniesienia sprzeciwu wobec przetwarzania Pana/Pani danych
            osobowych, ze względu na Pana/Pani szczególną sytuację – w
            przypadkach gdy Pana/Pani dane osobowe są przetwarzane na podstawie
            prawnie uzasadnionego interesu Spółki;
          </li>
          <li>
            prawo do przenoszenia Pana/Pani danych osobowych tj. prawo do
            otrzymania od Spółki danych osobowych, w ustrukturyzowanym,
            powszechnie używanym formacie informatycznym nadającym się do
            odczytu maszynowego; może Pan/Pani przesłać te dane osobowe innemu
            administratorowi danych osobowych lub zażądać, aby Spółka przesłała
            te dane osobowe do innego administratora danych osobowych, jeśli
            takie przesłanie jest technicznie możliwe;
          </li>
          <li>
            prawo do cofnięcia zgody na przetwarzanie danych osobowych w
            dowolnym momencie, co pozostaje jednak bez wpływu na zgodność z
            prawem przetwarzania, którego dokonano na podstawie zgody przed jej
            cofnięciem;
          </li>
          <li>
            prawo do wniesienia skargi do organu nadzorczego tj. Prezesa Urzędu
            Ochrony Danych Osobowych, jeśli Pan/Pani uważa, że Spółka przetwarza
            Pana/Pani dane osobowe niezgodnie z prawem.
          </li>
        </ul>
        <span>VI. Informacja o wymogu/dobrowolności podania danych</span>
        <p>
          Podanie przez Pana/Panią danych jest konieczne do zawarcia umowy oraz
          jej wykonania i rozliczenia. Jeżeli Pan/Pani nie poda Spółce danych,
          Spółka będzie zmuszona odmówić zawarcia umowy.
        </p>
        <span>
          VII. Przetwarzanie danych osobowych w sposób zautomatyzowany Pana/Pani
          dane osobowe nie będą przetwarzane w sposób zautomatyzowany (w tym w
          formie profilowania).
        </span>
      </div>
    </section>
  );
};

export default PrivacyPolicyContent;
